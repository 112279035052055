/**
 * NAIC InsData Company Service API
 * NAIC InsData Company Service API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: fstier2@naic.internal
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CompaniesStatementsSearchCriteria } from '../model/company/companiesStatementsSearchCriteria';
import { CompaniesStatementsSearchResults } from '../model/company/companiesStatementsSearchResults';
import { CompanyStatementsDetailSearchResult } from '../model/company/companyStatementsDetailSearchResult';
import { DownloadLinkCriteria } from '../model/company/downloadLinkCriteria';
import { OrderYears } from '../model/company/orderYears';
import { ProductData } from '../model/company/productData';
import { ProductDataKey } from '../model/company/productDataKey';
import { ProductDataKeys } from '../model/company/productDataKeys';
import { StatementTypes } from '../model/company/statementTypes';
import { States } from '../model/company/states';
import { SubmissionFilingPeriods } from '../model/company/submissionFilingPeriods';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CompanyService {

    protected basePath = 'https://localhost:9443';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Download zip file by product data key (Only for Admin Users).
     * Download zip file by product data key (Only for Admin Users).
     * @param productDataKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFileByProductDataKey(productDataKey: ProductDataKey, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadFileByProductDataKey(productDataKey: ProductDataKey, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadFileByProductDataKey(productDataKey: ProductDataKey, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadFileByProductDataKey(productDataKey: ProductDataKey, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productDataKey === null || productDataKey === undefined) {
            throw new Error('Required parameter productDataKey was null or undefined when calling downloadFileByProductDataKey.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/naicDownloadFile`,
            productDataKey,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download all files for an order
     *
     * @param userId The user UUID for the user who purchased the order.
     * @param orderId The order UUID for the order that needs to be fetched.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadOrderFiles(userId: string, orderId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public downloadOrderFiles(userId: string, orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public downloadOrderFiles(userId: string, orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public downloadOrderFiles(userId: string, orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadOrderFiles.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling downloadOrderFiles.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post(`${this.basePath}/naicOrderFiles/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(orderId))}`,
            null,
            {
                responseType: 'text',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download link for an order
     *
     * @param downloadLinkCriteria
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadOrderLink(downloadLinkCriteria: DownloadLinkCriteria, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public downloadOrderLink(downloadLinkCriteria: DownloadLinkCriteria, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public downloadOrderLink(downloadLinkCriteria: DownloadLinkCriteria, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public downloadOrderLink(downloadLinkCriteria: DownloadLinkCriteria, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (downloadLinkCriteria === null || downloadLinkCriteria === undefined) {
            throw new Error('Required parameter downloadLinkCriteria was null or undefined when calling downloadOrderLink.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<string>>(`${this.basePath}/naicOrderLink`,
            downloadLinkCriteria,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch a company&#39;s set of quarterly and annual statements available in the system.
     * Fetch a company&#39;s set of quarterly and annual statements available in the system.
     * @param companyCoCode NAIC Company CoCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchCompanyStatementsDetails(companyCoCode: number, observe?: 'body', reportProgress?: boolean): Observable<CompanyStatementsDetailSearchResult>;
    public fetchCompanyStatementsDetails(companyCoCode: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyStatementsDetailSearchResult>>;
    public fetchCompanyStatementsDetails(companyCoCode: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyStatementsDetailSearchResult>>;
    public fetchCompanyStatementsDetails(companyCoCode: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyCoCode === null || companyCoCode === undefined) {
            throw new Error('Required parameter companyCoCode was null or undefined when calling fetchCompanyStatementsDetails.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CompanyStatementsDetailSearchResult>(`${this.basePath}/naicCompanyStatementDetails/${encodeURIComponent(String(companyCoCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch the set of available order years.
     * Fetch the set of available order years.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchOrderYears(observe?: 'body', reportProgress?: boolean): Observable<OrderYears>;
    public fetchOrderYears(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderYears>>;
    public fetchOrderYears(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderYears>>;
    public fetchOrderYears(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrderYears>(`${this.basePath}/orderYears`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for a set of companies statement details data based on product data keys.
     * Search for a set of companies statement details data based on product data keys.
     * @param productDataKeys
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchProductDatasForProductDataKeys(productDataKeys: ProductDataKeys, observe?: 'body', reportProgress?: boolean): Observable<Array<ProductData>>;
    public fetchProductDatasForProductDataKeys(productDataKeys: ProductDataKeys, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductData>>>;
    public fetchProductDatasForProductDataKeys(productDataKeys: ProductDataKeys, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductData>>>;
    public fetchProductDatasForProductDataKeys(productDataKeys: ProductDataKeys, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productDataKeys === null || productDataKeys === undefined) {
            throw new Error('Required parameter productDataKeys was null or undefined when calling fetchProductDatasForProductDataKeys.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ProductData>>(`${this.basePath}/naicProductDataKeysDetails`,
            productDataKeys,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch the list of statement types.
     * Fetch the list of statement types.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchStatementTypes(observe?: 'body', reportProgress?: boolean): Observable<StatementTypes>;
    public fetchStatementTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatementTypes>>;
    public fetchStatementTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatementTypes>>;
    public fetchStatementTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StatementTypes>(`${this.basePath}/statementTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch the set of states/districts.
     * Fetch the set of states/districts.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchStates(observe?: 'body', reportProgress?: boolean): Observable<States>;
    public fetchStates(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<States>>;
    public fetchStates(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<States>>;
    public fetchStates(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<States>(`${this.basePath}/states`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch the list of submission filing periods.
     * Fetch the list of submission filing periods.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchSubmissionFilingPeriods(observe?: 'body', reportProgress?: boolean): Observable<SubmissionFilingPeriods>;
    public fetchSubmissionFilingPeriods(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionFilingPeriods>>;
    public fetchSubmissionFilingPeriods(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionFilingPeriods>>;
    public fetchSubmissionFilingPeriods(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SubmissionFilingPeriods>(`${this.basePath}/submissionFilingPeriods`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for NAIC Insurance companies statements
     * Search for NAIC Insurance companies statements
     * @param searchCriteria
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchNaicCompaniesStatements(searchCriteria: CompaniesStatementsSearchCriteria, observe?: 'body', reportProgress?: boolean): Observable<CompaniesStatementsSearchResults>;
    public searchNaicCompaniesStatements(searchCriteria: CompaniesStatementsSearchCriteria, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompaniesStatementsSearchResults>>;
    public searchNaicCompaniesStatements(searchCriteria: CompaniesStatementsSearchCriteria, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompaniesStatementsSearchResults>>;
    public searchNaicCompaniesStatements(searchCriteria: CompaniesStatementsSearchCriteria, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (searchCriteria === null || searchCriteria === undefined) {
            throw new Error('Required parameter searchCriteria was null or undefined when calling searchNaicCompaniesStatements.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CompaniesStatementsSearchResults>(`${this.basePath}/naicCompaniesStatementsSearches`,
            searchCriteria,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
