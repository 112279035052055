/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * !
 * Copyright 2021 National Association of Insurance Commissioners
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".second-bar[_ngcontent-%COMP%]{background:#2f71aa;color:#fff;justify-content:flex-end}.second-bar[_ngcontent-%COMP%]   .nav-item.active[_ngcontent-%COMP%] > a[_ngcontent-%COMP%], .second-bar[_ngcontent-%COMP%]   .nav-item[_ngcontent-%COMP%]:hover > a[_ngcontent-%COMP%], .second-bar[_ngcontent-%COMP%]   .navbar-toggler[_ngcontent-%COMP%]{background:#b4cbde}.second-bar[_ngcontent-%COMP%]   .nav-item.active[_ngcontent-%COMP%] > a[_ngcontent-%COMP%]{color:#000}.second-bar[_ngcontent-%COMP%]   .nav-item[_ngcontent-%COMP%] > a[_ngcontent-%COMP%]{color:#fff;padding:10px}.second-bar[_ngcontent-%COMP%]   .nav-item[_ngcontent-%COMP%] > a[_ngcontent-%COMP%], .second-bar[_ngcontent-%COMP%]   .nav-item[_ngcontent-%COMP%] > a[_ngcontent-%COMP%]:hover{text-decoration:none}@media (min-width:768px){.second-bar[_ngcontent-%COMP%]{padding-top:0;padding-bottom:0}}.brandmark[_ngcontent-%COMP%]{height:22px;margin-top:-3px}"];
export { styles as styles };

