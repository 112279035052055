/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import { PaginationComponent } from '../public/pagination/pagination.component';
import {TrimPipe} from './pipes/trim.pipe';

@NgModule( {
  imports: [CommonModule],
  declarations: [
  	PaginationComponent,
  	TrimPipe,
  ],
  exports: [
  	CommonModule,
  	TrimPipe,
  	FormsModule,
  	PaginationComponent,
  ],
} )
export class SharedModule { }
