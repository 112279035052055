<router-outlet *ngIf="loggedInNow"></router-outlet>
<app-insdata-loading-spinner></app-insdata-loading-spinner>
<app-insdata-message *ngIf="!isInApp()"></app-insdata-message>
<div class="full-page" *ngIf="!loggedInNow">
  <div>
    <app-banner></app-banner>
    <app-header></app-header>
    <div class="container-fluid">
      <div class="card mt-2">
        <p class="card-body">You are not logged in. Please sign up or login.</p>
      </div>
    </div>
  </div>

  <div *ngIf="bannerInfo.bannerStatus" style="margin: auto; width: 100%;">
    <div class="units my-center">
      <span class="my-card fa fa-wrench fa-5x fa-inverse goldBack"></span><br>
      <span class="my-card fa fa-wrench fa-5x fa-inverse goldBack"></span>
    </div>
    <div class="mntNoticeDiv">
      <h3>SERVICE INTERRUPTION NOTICE:</h3><br>
      <p>{{bannerInfo.bannerText}}</p><br><br>
    </div>
  </div>

  <app-footer></app-footer>
</div>


